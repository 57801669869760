import React, { Component, useEffect, useState} from 'react';
import $ from 'jquery';
//import './dashboard.scss'
import Grid from '@material-ui/core/Grid';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import PrivateRoute from '../src/Config/Private'
import Kyc from './kyc';
import Login from '../src/login';

const fakeAuthCentralState = {
  isAuthenticated: false,
  authenticate(callback) {
     this.isAuthenticated = true;
     setTimeout(callback, 300);
  },  
};
class MainRoute extends Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef()
    this.state = {
      isNavBarHidden: false,
       loggedIn: false,
    }
  }

  componentDidMount(){
    var navBarHeight = $('.navBar').outerHeight()
    // console.log("height of navbar", navBarHeight);
  }

  render() {
    
    const Page404 = ({ location }) => (
      <div>
        <code>{location.pathname} <h2>404 Page not Found
          {/* {console.log(location.path,".....")} */}
          </h2></code>
      </div>
   );
    // console.log('routers are called', this.props)
    return (
      <Router>
        <div>
          
           <Switch>
              <Route exact strict path="/" component={Kyc} />
              {/* {parseInt(localStorage.getItem("group")) !== constant.rent_user ?  */}
              {/* <PrivateRoute exact strict path="/" component={Kyc} />  :  */}
              {/* <PrivateRoute exact strict path="/" component={JswComponent} />   }              */}
          </Switch>
        
        </div>
          {/* <Route component={Page404} />  */}
      </Router>
    );
  }
}

export default MainRoute;
